.custom-select {
    cursor: pointer;

    & .custom-select-arrow {
        transition: 0.3s ease-in-out;
    }
    & .scr-grey{
        color: var(--text-grey);
    }
 
    .options {
        position: absolute;
        width: 200px;
        border-radius: 7px;
        border: 1px solid var(--borders-grey);
        background: black;
        z-index: 1;
        transition: all .4s ease-in-out;
        overflow: hidden;

        & ul {
            list-style-type: none;
            color: white;
            width: 100%;
            background-color: transparent;
            padding: 10px 0px;
            align-items: flex-start;
            justify-content: flex-start;
            margin: 0;

            & li {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                margin-bottom: 2px;
                margin-top: 2px;
                width: 100%;
                padding: 5px;
                padding-left: 10px;
                font-size: 16px;
                color: white;
                
               @media (min-width: 1250px) {
                color: var(--text-grey);
                }
            }
            & li.seperator{
                margin-bottom: 10px;
                margin-top: 5px;
                padding:0;
                height: 1px;
                background-color: var(--borders-grey) !important;
            }
            & li:last-of-type {

                font-size: 16px;
            }
            & li[data-language] img{
                margin-right: 10px;
            }
            & li[data-language]:hover {
                background: var(--selection);
                color: white;
            }
       

            & li img {
                width: 20px;         
                pointer-events: none;
            }
            & li.skin_logos{
                cursor: initial;
                margin: 0;
                padding:0;
                margin-top: -10px;
                margin-bottom: -5px;
               
            }
            & li.skin_logos img {
                height: 40px;
                width: auto;

            }

            @media (min-width: 1250px) {
                color: black;
            }
        }

        @media (min-width: 1250px) {
            width: 210px;
            height: 385px;
            background: white;
            transform: translateX(-155px);
        }

    }

    & button {
        background-color: transparent;
        color: white;
        border: 1px solid var(--borders-grey);
        border-radius: 7px;
        width: 200px;
        padding: 5px 10px;
        margin-bottom: 3px;
        justify-content: space-between;
        
        & >*{
            pointer-events: none;
        }

        & div>img {
            width: 20px;
            margin-left: 10px;
        }

        & img {
            margin-right: 0;
            border-radius: 50%;

            @media (min-width: 1250px) {
                width: 18px;
            }
        }

        @media (min-width: 1250px) {
            padding: 5px;
            margin: 0;
        }
    }
}