.slider-wrapper {
    & .btnNaked {
        padding: 10px 0;
        font-size: 16px;

        & .button-underline {
            width: 100%;
        }
    }

    & .slider {
        height: auto;
        width: calc(100vw - 40px);
        max-width: 800px;
        overflow: hidden;
        position: relative;
        margin: 0 auto;
        & .slides-container {
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            align-self: flex-start;
            transition: 0.5s ease-in-out;
            padding: 10px 0;
            &>* {
                width: calc(calc(100vw - 50px));
                max-width: 790px;
                margin: 0 5px;
            }
        }

    }

    & .selected-tab-button-wrapper {
        height: 16px;
        width: 16px;
        border-radius: 8px;
        border: 1px solid var(--selection);
        background-color: white;
        outline: none;
        margin-right: 5px;
        margin-left: 5px;
        margin-top: 10px;
        position: relative;

        & .selected-tab-button-decoration {
            position: absolute;
            width: 18px;
            height: 18px;
            border: 1px solid var(--selection);
            background-color: var(--selection);
            z-index: 1000;
            margin: 0;
            padding: 0;
            top: -2px;
            left: -1px;
            box-sizing: border-box;
            border-radius: 50%;
            animation-iteration-count: initial;
            animation-direction: normal;
            animation-duration: .3s;
            animation-timing-function: linear;
        }
    }

    & .tab-buttons-wrapper {
        justify-content: space-evenly;
        position: relative;
        
    }

    & .seperator::after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        height: 1px;
        width: calc(100% - 20px);
        transform: translateX(-50%);
        background-color: #DADADA;
    }

    & .draggable {
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        cursor: grab;
    }

    & .draggable:active {
        cursor: grabbing;
    }

    @keyframes decoAnimation {
        0% {
            transform-origin: center right;
            transform: translateX(calc((var(--distance) * 26px))) scaleX(1) scaleY(1);
        }

        50% {
            transform: translateX(calc((var(--distance) * 26px * 0.5))) scaleX(2.1) scaleY(0.8);
        }

        100% {
            transform: translateX(0px) scaleX(1) scaleY(1);
        }
    }
}