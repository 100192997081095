#testimonials {
    color: white;
    background: #2D3840;
    position: relative;
    overflow: hidden;
    justify-content: space-between;
    min-height: unset;
    @media (min-width: 1100px) {
        padding: 40px 32% 40px 32%;
    }
    & .circle-decoration {
        position: absolute;
        display: block;
        width: 450px;
        height: 450px;
        left: 50%;
        top: -20px;
        background-color: rgba(196, 196, 196, .15);
        border-radius: 50%;
        z-index: 0;
    }

    & .testimonial {
        & p {
            font-style: italic;
            text-align: center;
            padding: 0 15px;
        }

        & a {
            color: white;
            text-decoration: none;
            font-weight: bold;
        }
    }
}