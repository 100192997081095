nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10000;

  & .main-navigation,
  & .sub-navigation {
    width: 100%;
    height: 100%;
    justify-content: space-between;
    transition: background-color 0.3s ease-in-out;

    @media (min-width: 1250px) {
      padding: 10px 40px;
    }
  }

  & .main-navigation {
    padding: 10px 10px;
    z-index: 1001;

    & .nav__logo {
      display: flex;
      z-index: 10;

      & img {
        transform-origin: left center;
        transform: scale(1.1);
        height: 30px;
        pointer-events: none;
        width: 60px;
        @media (min-width: 1250px) {
          transform: scale(1.3);
        }
      }
    }

    & .nav__user {
      position: relative;
      z-index: 10;

      & a {
        font-weight: normal;
      }

      & .mob-nav {
        margin: 3px;
        padding: 7px 15px;
        @media (max-width: 360px) {
          padding: 5px 8px;
        }
      }
      & .longWordsBtn {
        padding: 7px 10px;
      }
    }

    & .nav__links-wrapper {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.9);
      padding: 30px;
      transition: 0.3s ease-in-out;
      z-index: 100;

      & .nav__links {
        width: 100%;
        list-style-type: none;
        padding: 0;
        height: 100%;
        justify-content: space-evenly;
        z-index: 20;
        text-align: center;

        & a {
          position: relative;
          overflow: hidden;
          font-weight: bold;
          font-size: 25px;

          &::after {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 50%;
            width: 100%;
            transform: translate(-50%, 10px) scaleX(0);
            height: 2px;
            background-color: red;
            opacity: 0;
            transition: all 0.3s;
          }

          &:hover::after {
            opacity: 1;
            transform: translate(-50%, 5px) scaleX(1);
          }

          @media (max-width: 320px) {
            font-size: 20px;
          }

          @media (min-width: 1250px) {
            font-size: 18px;
            font-weight: normal;
          }
        }

        & li {
          @media (min-width: 1250px) {
            margin-right: 30px;
          }
        }

        & > li:last-child {
          font-size: 10px;
        }

        @media (min-width: 1250px) {
          margin: 0;
          padding-left: 5%;
          flex-direction: row;
          height: auto;
          justify-content: flex-start;
        }
      }

      @media (min-width: 1250px) {
        position: relative;
        top: unset;
        left: unset;
        transform: translateY(0) !important;
        padding: 0;
        background-color: transparent;
      }
    }

    & .nav__menu-mobile-button {
      position: relative;
      background-color: transparent;
      width: 28px;
      height: 23px;
      border-radius: 10px;
      border: none;
      outline: none;
      z-index: 1000;
      padding: 0;
      margin-right: 20px;

      & span {
        pointer-events: none;
        display: block;
        height: 3px;
        width: 28px;
        background-color: white;
        transform-origin: center left;
        transition: 0.3s ease-in-out;
        position: absolute;
        left: 0;

        &:nth-of-type(1) {
          top: 0;
        }

        &:nth-of-type(2) {
          top: calc(50% - 1px);
        }

        &:nth-of-type(3) {
          bottom: 0;
        }
      }
    }

    & .user-actions-wrapper {
      width: 500px;
      justify-content: flex-end;
    }

    & .generic-button {
      padding: 5px 20px;
      @media (max-width: 365px) {
        padding: 5px 10px;
      }
      @media (min-width: 1250px) {
        margin: 0 10px;
      }
    }

    & #lang-button {
      @media (min-width: 1250px) {
        border: none;
      }
    }

    @media (min-width: 1250px) {
      padding: 15px 40px;
    }
  }

  & .sub-navigation {
    justify-content: space-between;
    font-size: 14px;
    height: 40px;
    z-index: 1000;
    padding: 10px 50px 10px 40px;

    & .nav__links {
      width: 100%;
      list-style-type: none;
      padding: 0;
      height: 100%;
      justify-content: space-evenly;
      z-index: 20;

      & a {
        position: relative;
        overflow: hidden;

        &::after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          transform: translate(-50%, 10px) scaleX(0);
          height: 2px;
          background-color: red;
          opacity: 0;
          transition: all 0.3s;
        }

        &:hover::after {
          opacity: 1;
          transform: translate(-50%, 5px) scaleX(1);
        }
      }

      & li {
        @media (min-width: 1250px) {
          margin-right: 30px;
        }
      }

      & .sub-navigation .nav__links,
      & .sub-navigation p {
        margin: 0;
        padding: 0;
      }
    }
  }
}
