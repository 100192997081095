:root {
  --red: #ed1c24;
  --red-darken: #e21219;
  --white: hsl(0, 0%, 100%);
  --white-darken: hsl(210, 17%, 98%);
  --selection: #388D79;
  --text-grey:#585858;
  --borders-grey: #c4c6c5;
}

* {
  box-sizing: border-box;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth;
}

button,
summary,
a {
  cursor: pointer;
}

.red {
  color: var(--red);
}

.checker {
  position: absolute;
  height: 60px;
  width: 1px;
  pointer-events: none;
  z-index: -1;
}

/*======================= button styling========================= */
.generic-button {
  padding: 5px 30px;
  box-sizing: border-box;
  font-weight: normal;
  outline: none;
  position: relative;
  text-decoration: none;
  margin: 10px;
  border-radius: 2px;
  border-style: solid;
  border-width: 1px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnRed {
  color: white;
  background-color: var(--red);
  border-color: var(--red);
}

.btnWhite {
  color: black;
  background-color: var(--white);
  border-color: black;
}

.btnGhost {
  color: var(--white);
  border-color: var(--white);
  background-color: transparent;
}

.btnNaked {
  background-color: transparent;
  border-color: transparent;
}

.btnRed:hover {
  background-color: var(--red-darken);
  border-color: var(--red-darken);
}

.btnWhite:hover {
  background-color: var(--white-darken);
}

.btnGhost:hover {
  background-color: var(--red-darken);
  border-color: var(--red-darken);
}

.button-underline {
  position: absolute;
  width: calc(100% - 50px);
  height: 2px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: var(--red);
  border-radius: 1px;
}

/*======================= button styling end===================== */
.flex-center-c {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.flex-center-r {
  display: flex;
  align-items: center;
  justify-content: center;
}

.app {
  min-height: 100vh;
  padding-top: 80px;
}

.app__section {
  min-height: 70vh;
  padding: 20px;
}


.back-link {
  color: #6C757D;
  text-decoration: none;
  align-self: flex-start;
  margin-bottom: 20px;
}

.circle-decoration {
  pointer-events: none;
}

p {
  line-height: 140%;
}

a {
  position: relative;
  text-decoration: none;
}

a>img,
button>img {
  margin-right: 10px;
}

.custom__label {
  margin-bottom: 0;
  pointer-events: none;
  transition: 0.3s ease-in-out;
  transform-origin: center left;
  width: 100%;
  font-size: 18px;
  font-weight: normal;
}

input[type='text'],
input[type='email'],
input[type='password'] {
  border: none;
  border-bottom: 1px solid white;
  outline: none;
  padding: 10px;
  background-color: transparent;
  color: white;
  width: 100%;
  margin-bottom: 15px;
}

::selection {
  color: white;
  background-color: var(--selection);
}



.activeRoute,
.activeRouteSub {
  color: black !important;
  font-weight: bold !important;
}

.activeRouteSubNotScrolled {
  font-weight: bold !important;
}

.activeRouteNotScrolled {
  font-weight: bold !important;
}

.subNavActiveRoute {
  font-weight: bold;
  color: black !important;
}

.subNavActiveRouteNotScrolled {
  font-weight: bold;
}

.activeRoute::after {

  opacity: 1 !important;
  transform: translateX(-50%) scale(1) !important;
}


.page-wrapper {
  min-height: calc(100vh - 100px);
}

.main-content-container {
  width: 100%;
}
@media (min-width: 1250px) {
  .main-content-container {
    width: 800px;
  }
}