#hero {
    position: relative;
    min-height: 575px;
    justify-content: flex-start;
    padding-top: 100px;
    height: 80vh;
    margin-bottom: 100px;

    @media (min-width: 700px) {
        height: 78vh;
    }

    @media (orientation: landscape) and (max-height: 600px) {
        margin-bottom: 0;
        min-height: unset;
        height: 100vh;
    }

    & #heroVideo {
        position: absolute;
        top: 0;
        left: 0;
        margin: 0;
        padding: 0;
        object-fit: cover;
        z-index: -1;
        max-height: 100vh;
        max-width: 100%;

    }

    & h1 {
        font-size: 45px;
        font-weight: bold;
        color: white;
        padding: 0;
        max-width: 80vw;
        margin: 0;
        text-align: left;
        width: 100%;
        z-index: 0;
        

        @media (min-width: 700px) {
            transform: translateY(10vh);
            max-width: 90vw;
            font-size: 50px;
        }

        @media (min-width: 1250px) {
            max-width: 800px;
            font-size: 55px;
            
        }

        @media (min-width: 1720px) {
            padding-left: 12%;
            max-width: 60vw;
        }
    }

    & .hero__ctas {
        margin-top: 60px;
        width: 100%;
        z-index: 1;

        @media (min-width: 700px) {
            margin-top: 15vh;
        }

        & a {
            width: 200px;
            font-size: 16px;
            padding: 10px 30px;
            font-weight: bold;
        }
    }

    & .decoContainer {
        position: absolute;
        bottom: -120px;
        width: 90%;
        height: 300px;
        z-index: -1;

        @media (orientation: landscape) and (max-height: 600px) {
            display: none;
        }

        & .himgcontainer {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 100%;
            max-width: 600px;
            max-height: 600px;

            @media (min-width: 700px) {
                top: -20%;
                left: 30%;
            }


            &:nth-of-type(even) {
                bottom: 50px;
                left: 100px;
                max-height: 200px;

                @media (min-width: 700px) {
                    top: unset;
                    left: 45%;
                    max-height: 300px;
                }
            }

            & .hero__fg-image,
            & .hero__fg-image-filler {
                object-fit: contain;
                height: 100%;
                width: 100%;
                position: absolute;
            }

            & .hero__fg-image:nth-of-type(even) {
                bottom: -50px;
            }

            & .hero__fg-image-filler {
                opacity: 0;
            }

        }

    }

}