#contact {
    position: relative;
    color: white;
    overflow: hidden;

    &::before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url('../../assets/images/contact-bg-mobile.jpg');
        background-size: cover;
        background-position: left center;
        background-repeat: no-repeat;
        background-attachment: fixed;
        z-index: -1;

        @media (min-width: 1100px) {
            background-image: url('../../assets/images/contact-bg-desk.jpg');
        }
    }

    @media (min-width: 1100px) {
        padding: 100px 25% 40px 25%;
    }


    & h1 {
        font-size: 38px;
        width: 100%;
        margin: 0;

        @media (min-width: 600px) {

            width: 100%;
            text-align: center;
        }

        @media (min-width: 1100px) {
            transform: translateY(40px);
            font-size: 144px;
            position: absolute;
            line-height: 0;
            top: 0;
            left: 10%;
            margin: 0;
            color: rgba(255, 255, 255, 0.3);
            z-index: 0;
            text-align: left;
        }

        & span:nth-last-of-type(1) {
            @media (min-width: 1100px) {
                display: none;
            }
        }

    }

    & .inputs-container {
        width: 100%;
        margin: 0;
        padding: 0;
        flex-wrap: wrap;

        max-width: 550px;

        @media (min-width: 1100px) {
            margin-top: 50px;
        }

        & .input-row:nth-of-type(1) {
            width: 45%;
            margin-right: 5%;
        }

        & .input-row:nth-of-type(2) {
            width: 45%;
            margin-left: 5%;
        }

        & .input-row:nth-of-type(3) {
            width: 100%;
        }

        & .input-row:nth-of-type(4) {
            width: 100%;
        }

        & .input-row:nth-last-child(1) {
            width: 100%;

            & a {
                margin-top: 30px;
                padding: 10px 20px;

                @media (min-width: 600px) {


                    align-self: flex-end;
                    margin-right: 20px;

                }
            }
        }
    }
}