#loader{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: whitesmoke;
    z-index: 100000;
    padding: 0;
}






