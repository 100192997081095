#about-us {
    background: #2D3840;
    color: white;
    position: relative;
    overflow: hidden;

    @media (min-width: 1100px) {
        flex-direction: row;
        padding: 100px 25% 40px 25%;
    }

    & div {
        width: 100%;

        @media (min-width: 1100px) {
            z-index: 1;

            &:nth-of-type(1) {
                margin-right: 10%;
                align-self: flex-end;
            }
        }

        & .about-us__ul {
            list-style-type: none;
            padding: 0;
            z-index: 1;

            & li {
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;

                @media (min-width: 1100px) {
                    max-width: 400px;
                }

                & p {
                    margin: 0;
                }

                & img {
                    margin-right: 10px;
                    transform: translateY(-5px);
                }
            }
        }
    }

    & h1,
    & h3 {
        font-size: 38px;
        text-align: left;
        width: 100%;
        margin: 0;
    }

    & h1 {
        @media (min-width: 1100px) {
            transform: translateY(40px);
            font-size: 144px;
            position: absolute;
            line-height: 0;
            top: 0;
            left: 10%;
            margin: 0;
            color: #404951;
            z-index: 0;
        }

        & span {
            @media (min-width: 1100px) {
                &:nth-last-of-type(1) {
                    display: none;
                }
            }
        }

    }

    & .circle-decoration {
        position: absolute;
        display: block;
        width: 450px;
        height: 450px;
        left: 50%;
        top: 20%;
        background-color: rgba(196, 196, 196, .15);
        border-radius: 50%;
        z-index: 0;

        @media (min-width: 1100px) {
            width: 50vw;
            height: 50vw;
        }
    }

}