#movements {
    position: fixed;
    top: 0;
    left: 0;
    width: 90px;
    height: 20px;
    background-color: rgba(30, 30, 30, .9);
    border-radius: 25px;
    transform: translateX(-50%);
    opacity: 0;
    z-index: -10;
    transition: opacity .1s ease-in-out;

    &::before,
    &::after {
        content: '<';
        display: block;
        position: absolute;
        left: 0;
        border-radius: 50%;
        height: 100%;
        width: 20px;
        background: rgba(122, 118, 118, 1);
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 15px;
        z-index: 2;
        color: white;
    }

    &::after {
        content: '>';
        left: unset;
        right: 0;
    }

    & .movements__left-line,
    & .movements__right-line {
        position: absolute;
        height: 1px;
        width: 45px;
        top: 50%;
        background: white;
        box-shadow: 0 0 6px white, 0 0 3px white, 0 0 9px white;
        overflow: hidden;
        z-index: 1;
    }

    & .movements__left-line::after,
    & .movements__right-line::after {
        content: '';
        position: absolute;
        width: 45px;
        height: 2px;
        background-image: linear-gradient(to right, transparent, red, transparent);
        filter: blur(.5px);
    }


    & .movements__left-line::after {
        left: 50%;
        animation: flash-left 2s infinite ease-in-out;
    }

    & .movements__right-line {
        left: 50%;

        &::after {
            right: 50%;
            animation: flash-right 2s infinite ease-in-out;
        }

    }

    @keyframes flash-right {
        0% {
            transform: translateX(-20%);
        }

        50% {
            transform: translateX(100%);
        }

        100% {
            transform: translateX(-20%);
        }
    }

    @keyframes flash-left {
        0% {
            transform: translateX(20%);
        }

        50% {
            transform: translateX(-100%);
        }

        100% {
            transform: translateX(20%);
        }
    }
}