.step {
    opacity: 0;
    transform: translateX(10%);
    transition: all .4s ease-in-out;
    align-items: flex-start;
    margin-bottom: 40px;

    & section {
        width: 100%;
        text-align: left;


        &>* {
            width: 100%;
            text-align: left;
        }

        &>h3 {
            margin: 0;
            font-size: 20px;
            font-weight: bold;
        }

        &>p {
            margin: 0;
            margin-top: 15px;
            &>a{
                color: black;
                font-weight: bold;
            }
        }
    }

    & .step__image {
        margin-right: 20px;
        transform: translateY(-10px);
    }

    @media (min-width: 600px) {
        max-width: 500px;
    }

    @media (min-width: 1100px) {
        transform: translateX(0);
        transform: translateY(15%);
        flex-direction: column;
        max-width: 340px;
        align-items: center;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.25);
        border-radius: 5px;
        padding: 25px;

        & section>p,
        & section>h3 {
            text-align: center;
        }

        & .step__image {
            margin-right: 0;
        }

    }
}