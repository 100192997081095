.footer {
    background-color: #161616;
    min-height: 150px;
    color: white;
    padding: 30px 20px;

    & .footer-row {
        padding: 0;

        @media (min-width: 800px) {
            flex-direction: row;
        }

        & h2 {
            @media (min-width: 800px) {
                margin-top: 0;
            }
        }
    }

    &>div {
        margin-bottom: 20px;

        @media (min-width: 800px) {
            height: 100px;
            margin: 0 60px;
            justify-content: flex-start;
        }
    }

    & .logos {
        & img {
            max-height: 56px;
            margin: 0 15px;
        }
    }

    & .social-media-links {
        & a {
            margin: 10px 15px;

            & img {
                margin: 0;
                width: 24px;
            }
        }
    }

    & .copyright-container {
        text-align: center;
        font-size: 12px;
        color: #828282;
    }
    
    & .useful-links a{
        color: white;
    }
}