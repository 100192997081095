#steps {
    z-index: 1;

    & #stepsContainer {
        margin-top: 50px;
        width: 100%;
        overflow: hidden;
        padding-top: 30px;

        @media (min-width: 1100px) {
            flex-direction: row;
            justify-content: space-evenly;

        }
    }

    & h1 {
        text-align: center;
        font-size: 35px;

        @media (min-width: 1100px) {
            font-size: 45px;
        }
    }
}