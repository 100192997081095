#to-top-button,
#to-back-button {
    position: fixed;
    bottom: 20px;
    z-index: 9;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    border: none;
    opacity: 0;
    transition: all .5s;
}

#to-back-button {
    background-color: #6C757D;
    left: 20px;
}

#to-top-button {
    background-color: #ED1C24;
    right: 20px;
}

#to-top-button>*,
#to-back-button>* {
    transform-origin: center;
    width: 25px;
    margin: 0;
}