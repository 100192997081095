#what-we-offer {
    position: relative;
    overflow: hidden;
    background-color: white;

    @media (min-width: 1100px) {
        padding: 140px 25% 40px 25%;
    }

    & h1 {
        width: 100%;
        font-size: 38px;
        margin: 10px 0;
        z-index: 0;

        @media (min-width: 1100px) {
            transform: translateY(40px);
            font-size: 144px;
            position: absolute;
            line-height: 0;
            top: 0;
            left: 10%;
            margin: 0;
            color: #404951;
            z-index: 0;
        }

        & span {
            @media (min-width: 1100px) {

                &:nth-last-of-type(1) {
                    display: none;
                }
            }
        }

    }

    & .generic-button {
        margin: 0;
    }

    & .circle-decoration {
        position: absolute;
        display: block;
        width: 450px;
        height: 450px;
        left: 0;
        top: 0;
        background-color: rgba(196, 196, 196, .2);
        border-radius: 50%;
        z-index: -1;
        transform-origin: center center;
        transform: translate(-20%, 20%);

        @media (min-width: 1100px) {

            width: 800px;
            height: 800px;
            transform: translate(-100%, -40%);
        }
    }

}